<template>
  <div>
    <v-dialog v-model="dialog" width="800" persistent>
      <v-card>
        <v-card-title>
          <v-toolbar style="border-radius: 30px">Cadastrar membro</v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense class="mt-4">
              <v-col>
                <v-text-field label="Nome" v-model="form.name"></v-text-field>
              </v-col>
              <v-col
                ><masked-field
                  label="Data de nascimento"
                  v-model="form.birthDate"
                ></masked-field
              ></v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  label="Documento"
                  v-model="form.document"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  label="Tipo de documento"
                  :items="documentTypes"
                  v-model="form.documentType"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded @click="close" style="margin-right: 3vh">Fechar</v-btn>
          <v-btn rounded :loading="loading" @click="registerMember"
            >Cadastrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createStudent } from "@/api/api";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
    },
    category: {
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      documentTypes: [
        {
          text: "CPF",
          value: 0,
        },
        {
          text: "RG",
          value: 1,
        },
        {
          text: "CERTIDAO",
          value: 2,
        },
        {
          text: "DNE",
          value: 3,
        },
      ],
      form: {
        band: this.id,
        name: "",
        birthDate: "",
        document: "",
        documentType: null,
      },
    };
  },
  methods: {
    async registerMember() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.form.band = this.id;
        await createStudent(this.form)
          .then((res) => {
            this.$emit("saved", res.data);
          })
          .catch((err) => {
            this.$root.$emit("display-alert", {
              text: err.response.data.message,
              color: 1,
            });
            this.loading = false;
          });
        this.loading = false;
      }
    },
    close() {
      this.$emit("close");
    },
  },
  watch: {
    show: function (value) {
      this.dialog = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
