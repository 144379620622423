<template>
  <div>
    <v-app-bar></v-app-bar>
    <v-container>
      <v-row dense class="mt-8">
        <v-form ref="form" v-model="valid">
          <v-row dense>
            <v-col>
              <v-card
                min-width="85vw"
                elevation="1"
                style="background-color: transparent; border-radius: 30px"
              >
                <v-card-title>
                  <v-toolbar
                    background="transparent"
                    style="border-radius: 24px 24px 0px 0px"
                    class="toolbar d-flex align-center justify-center"
                    ><span class="text-h4 white--text"
                      >Registro de bandas</span
                    ></v-toolbar
                  >
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col class="d-flex align-center justify-center">
                      <v-btn @click="gotoForm('novo')">Adicionar banda</v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="d-flex align-center justify-center"
                      ><strong>Id</strong></v-col
                    >
                    <v-col class="d-flex align-center justify-center"
                      ><strong>Nome</strong></v-col
                    >
                    <v-col class="d-flex align-center justify-center"
                      ><strong>Categoria</strong></v-col
                    >
                    <v-col class="d-flex align-center justify-center"
                      ><strong>Ações</strong></v-col
                    >
                  </v-row>
                  <v-row
                    dense
                    v-for="(item, index) in data"
                    :key="index"
                    style="margin-top: 1vw"
                  >
                    <v-col class="d-flex align-center justify-center"
                      ><strong>{{ item.id }}</strong></v-col
                    >
                    <v-col class="d-flex align-start justify-center"
                      ><strong>{{ item.name }}</strong></v-col
                    >
                    <v-col class="d-flex align-center justify-center"
                      ><strong>{{ category(item.category) }}</strong></v-col
                    >
                    <v-col class="d-flex align-center justify-center"
                      ><strong
                        ><v-btn @click="gotoForm(item.id)"
                          >visualizar</v-btn
                        ></strong
                      ></v-col
                    >
                  </v-row>
                </v-card-text>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col>
          </v-row> </v-form
      ></v-row>
    </v-container>
  </div>
</template>

<script>
import { listBands } from "@/api/api";

export default {
  name: "BandsPage",
  async mounted() {
    await listBands(this.filters)
      .then((res) => {
        this.total = res.data.count;
        this.data = res.data.data;

        this.pages = parseInt(this.total / 10) + 1;
      })
      .catch(() => {});
  },
  data() {
    return {
      valid: false,
      total: 0,
      data: [],
      page: 1,
      pages: 1,
      filters: { limit: 1000, page: 1 },
      headers: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Nome",
          value: name,
        },
        {
          text: "Categoria",
          value: "category",
        },
      ],
    };
  },
  methods: {
    gotoForm(id) {
      this.$router.push({
        path: `/formulario/${id}`,
        params: id,
      });
    },
    changePage() {},
    category(cod) {
      switch (cod) {
        case 0:
          return "Mirim";
        case 1:
          return "Infantil";
        case 2:
          return "Infanto-Juvenil";
        case 3:
          return "Juvenil";
        case 4:
          return "Senior";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
