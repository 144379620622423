<template>
  <div data-app>
    <v-main>
      <router-view />
    </v-main>
    <SnackBar />
  </div>
</template>

<script>
import SnackBar from "./components/alerts/SnackBar.vue";

export default {
  name: "app",
  components: {
    SnackBar,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #e5e9ee;
  margin-top: 60px;
}
</style>
