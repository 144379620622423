import axios from "axios";

const instance = axios.create({
  baseURL: "https://gxr47okd8f.execute-api.us-east-1.amazonaws.com/dev",
  // baseURL: "http://localhost:3000",
});

export async function login(username, password) {
  return await instance.post("/users/login", { username, password });
}

export async function listBands({ limit = 10, page = 1 }) {
  return await instance.get(`/bands?size=${limit}&page=${page}`);
}

export async function filterBands(id) {
  return await instance.get(`/bands/${id}`);
}

export async function createBand(body) {
  return await instance.post("/bands", body);
}

export async function createStudent(body) {
  return await instance.post("/students", body);
}

export async function listStudents({ limit = 10, page = 1 }) {
  return await instance.get(`/students?size=${limit}&page=${page}`);
}

export async function filterOne(criteria) {
  return await instance.get(`/students/filter?criteria=${criteria}`);
}
