<template>
  <v-snackbar shaped v-model="snackbar" :timeout="timeout" :color="color">
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  mounted() {
    this.$root.$on("display-alert", (data) => {
      console.info("snack");
      this.text = data.text;
      this.color = this.colors[data.type];
      this.snackbar = true;
    });
  },
  data() {
    return {
      snackbar: false,
      color: "success",
      vertical: true,
      text: "",
      colors: ["primary", "error", "alert"],
      type: 0,
      timeout: 3000,
    };
  },
};
</script>

<style scoped></style>
