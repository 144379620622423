import Bands from "@/views/bands/Bands.vue";
import BandsForm from "@/views/bands/form/bandsForm.vue";
import Login from "@/views/Login.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/bandas",
    name: "bandas",
    component: Bands,
  },
  {
    path: "/formulario/:id",
    name: "formulario",
    component: BandsForm,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach();

export default router;
