<template>
  <div>
    <v-app-bar></v-app-bar>
    <v-container>
      <v-row dense class="mt-8">
        <v-form ref="form" v-model="valid">
          <v-row dense>
            <v-col>
              <v-card
                min-width="85vw"
                elevation="1"
                style="background-color: transparent; border-radius: 30px"
              >
                <v-card-title>
                  <v-toolbar
                    background="transparent"
                    style="border-radius: 24px 24px 0px 0px"
                    class="toolbar d-flex align-center justify-center"
                    ><span class="text-h4 white--text">Registro de bandas</span>
                    <v-spacer></v-spacer>

                    <v-btn rounded @click="registerBand" :disabled="editable"
                      >Cadastrar</v-btn
                    >
                  </v-toolbar>
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        label="Nome da banda"
                        v-model="form.name"
                        :readonly="editable"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        label="Categoria"
                        item-value="value"
                        item-text="text"
                        v-model="form.category"
                        :items="categories"
                        :readonly="editable"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <div v-if="isEditing">
                    <v-row dense>
                      <v-btn rounded @click="openDialog"
                        >Adicionar membro</v-btn
                      >
                      <v-col></v-col>

                      <v-text-field v-model="search"></v-text-field>
                    </v-row>

                    <v-data-table
                      :headers="headers"
                      :items="students"
                      :search="search"
                      :items-per-page="10000"
                      hide-default-footer
                    />
                  </div>
                  <student-dialog
                    :show="displayDialog"
                    :id="id"
                    :category="form.category"
                    @saved="appendStudent"
                    @close="displayDialog = !displayDialog"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { createBand, filterBands } from "@/api/api";

export default {
  name: "BandsForm",
  async mounted() {
    const id = this.$route.params.id;
    if (id !== "novo") {
      this.id = id;
      this.isEditing = true;

      await filterBands(id).then((res) => {
        this.students = res.data.students;
        this.form.name = res.data.name;
        this.form.category = res.data.category;
        this.editable = true;
      });
    }
  },
  data() {
    return {
      displayDialog: false,
      valid: false,
      editable: false,
      isEditing: false,
      search: "",
      id: null,
      students: [],
      headers: [
        { value: "name" },
        { value: "birthDate" },
        { value: "age" },
        { value: "document" },
      ],
      form: {
        name: "",
        category: null,
      },
      categories: [
        {
          value: 0,
          text: "Mirim",
        },
        {
          value: 1,
          text: "Infantil",
        },
        {
          value: 2,
          text: "Infanto-Juvenil",
        },
        {
          value: 3,
          text: "Juvenil",
        },
        {
          value: 4,
          text: "Senior",
        },
      ],
    };
  },
  methods: {
    async registerBand() {
      await createBand(this.form)
        .then((res) => {
          this.isEditing = true;
          this.id = res.data.id;
          this.$route.push({ path: "/formulario", params: { id: this.id } });
        })
        .catch(() => {});
    },
    appendStudent(item) {
      this.students.push(item);
      this.displayDialog = false;
    },
    openDialog() {
      this.displayDialog = true;
    },
    docType(cod) {
      switch (cod) {
        case 0:
          return "cpf";
        case 1:
          return "rg";
        case 2:
          return "certidao";
        case 4:
          return "dne";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
